import {
    reactive, ref, Ref, watch,
    onMounted
} from 'vue';
import initBuildAptChoose from '@/methods/building-apt-choose';
import { user } from '@/methods/rule';
import { account, office } from '@/data';
import { isCNServer } from '@/util/location';
import { PostRequest } from '@/api/base';

interface FormData {
    UnitID: string;
    RoomID: string;
    FirstName: string;
    LastName: string;
    Email: string;
    PhoneCode: string;
    MobileNumber: string;
    Phone: string;
    Phone2: string;
    Phone3: string;
    DepartmentID: string;
    Role: string;
    IsMulti: number;
}

const init = (id?: string) => {
    const basicData: FormData = reactive({
        UnitID: '',
        RoomID: '',
        FirstName: '',
        LastName: '',
        Email: '',
        PhoneCode: isCNServer() ? '86' : '',
        MobileNumber: '',
        Phone: '',
        Phone2: '',
        Phone3: '',
        DepartmentID: '',
        Role: '',
        IsMulti: 0
    });

    const checkCountryOrRegion: RuleMethod = (rule, value, callback) => {
        const isPhoneNumberEntered = basicData.MobileNumber !== '' || basicData.Phone !== '' || basicData.Phone2 !== '' || basicData.Phone3 !== '';
        if (value === '' && isPhoneNumberEntered) {
            return callback(new Error(WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextCountryRegion)));
        }
        return callback();
    };

    const rules: {
        [key in string]: any[]
    } = reactive({
        UnitID: [{ required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.DeviceDetailDetailUnitName), trigger: 'change' }],
        RoomID: [{ required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.RDeviceSearchLabelRoomName), trigger: 'change' }],
        FirstName: [{ required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelPropertyManageFirstName), trigger: 'blur' }],
        LastName: [{ required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelPropertyManageLastName), trigger: 'blur' }],
        Email: [{ validator: user.checkEmail, trigger: 'blur' }],
        PhoneCode: [{ validator: checkCountryOrRegion, trigger: 'change' }],
        MobileNumber: []
    });

    const accountType: Ref<'main' | 'sub' | ''> = ref('');
    if (id) {
        onMounted(() => {
            account.getUserInfoForPM(id, (data: FormData & { Role: string }) => {
                Object.keys(basicData).forEach((item) => {
                    const key = item as keyof FormData;
                    if (key === 'IsMulti') {
                        basicData[key] = data[key] || 0;
                        return;
                    }
                    basicData[key] = data[key] || '';
                });
                if (data.Role === '21') {
                    accountType.value = 'sub';
                } else {
                    accountType.value = 'main';
                }
                if (data.Email) {
                    rules.Email = [
                        { required: true, message: WordList.RuleEmailEmpty, trigger: 'blur' },
                        { validator: user.checkEmail, trigger: 'blur' }
                    ];
                }
                if (data.MobileNumber) {
                    rules.MobileNumber = [
                        { required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextMobileNumber), trigger: 'change' }
                    ];
                }
            });
        });
    }

    const {
        buildOptions, aptOptions, useRoom, setRoomOption
    } = initBuildAptChoose('all', 'aptAndName');

    watch(() => basicData.UnitID, (val) => {
        if (!id) {
            basicData.RoomID = '';
        }
        setRoomOption(val);
    });

    if (!id) {
        watch(() => basicData.RoomID, (val) => {
            if (useRoom.includes(val)) accountType.value = 'sub';
            else accountType.value = 'main';
        });
    }

    return {
        buildOptions,
        aptOptions,
        basicData,
        accountType,
        rules
    };
};

interface OfficeFormData {
    DepartmentID: string;
    FirstName: string;
    LastName: string;
    Email: string;
    PhoneCode: string;
    MobileNumber: string;
    EmployeeID: string;
    TempKeyPermission: string | number;
    HasIntercomAccess: string | number;
    Phone: string;
    CallType: string;
    EnableIpDirect: string;
    Role: string;
    IsMulti: number;
}

const officeInit = (id?: string) => {
    const basicData: OfficeFormData = reactive({
        DepartmentID: '',
        FirstName: '',
        LastName: '',
        Email: '',
        PhoneCode: isCNServer() ? '86' : '',
        MobileNumber: '',
        EmployeeID: '',
        TempKeyPermission: '1',
        HasIntercomAccess: '0',
        Phone: '',
        CallType: '0',
        EnableIpDirect: '1',
        Role: '30',
        IsMulti: 0
    });

    const checkCountryOrRegion: RuleMethod = (rule, value, callback) => {
        const isPhoneNumberEntered = basicData.MobileNumber !== '' || basicData.Phone !== '';
        if (value === '' && isPhoneNumberEntered) {
            return callback(new Error(WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextCountryRegion)));
        }
        return callback();
    };

    const rules: {
        [key in string]: any[]
    } = reactive({
        DepartmentID: [{ required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextDepartment), trigger: 'change' }],
        FirstName: [{ required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelPropertyManageFirstName), trigger: 'blur' }],
        LastName: [{ required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelPropertyManageLastName), trigger: 'blur' }],
        EmployeeID: [
            { required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextID), trigger: 'blur' },
            { validator: onBlurByEmployeeID, trigger: 'blur' }
        ],
        Email: [{ validator: user.checkEmail, trigger: 'blur' }],
        PhoneCode: [{ validator: checkCountryOrRegion, trigger: 'change' }],
        MobileNumber: []
    });
    async function onBlurByEmployeeID(rule: any, value: string, callback: any) {
        if (value === '') {
            return callback();
        }
        if (value !== '') {
            await new Promise((resolve, reject) => {
                let data = { };
                if (id) {
                    data = { EmployeeID: value, ID: id };
                } else {
                    data = { EmployeeID: value };
                }
                PostRequest('v3/web/office/user/checkEmployeeID', data, (res: {data: {IsExists: number}}) => {
                    if (res.data.IsExists === 1) {
                        reject(WordList.IDAlreadyExists);
                    } else {
                        resolve(true);
                    }
                });
            });
        }
    }
    if (id) {
        onMounted(() => {
            office.getOfficeUserInfo(id, (res: {
                data: {
                    details: OfficeFormData;
                };
            }) => {
                const officeData = res.data.details;
                Object.keys(basicData).forEach((item) => {
                    const key = item as keyof OfficeFormData;
                    if (key !== 'TempKeyPermission' && key !== 'HasIntercomAccess') {
                        if (key === 'IsMulti') {
                            basicData[key] = officeData[key] || 0;
                            return;
                        }
                        basicData[key] = officeData[key] || '';
                    } else {
                        basicData[key] = String(officeData[key]);
                    }
                });
                if (officeData.Email) {
                    rules.Email = [
                        { required: true, message: WordList.RuleEmailEmpty, trigger: 'blur' },
                        { validator: user.checkEmail, trigger: 'blur' }
                    ];
                }
                if (officeData.MobileNumber) {
                    rules.MobileNumber = [
                        { required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.ProperAllTextMobileNumber), trigger: 'change' }
                    ];
                }
            });
        });
    }

    const { buildOptions } = initBuildAptChoose('all');

    return {
        basicData,
        rules,
        buildOptions
    };
};

export default init;
export {
    FormData,
    officeInit,
    OfficeFormData
};
